<script setup lang="ts">

useDescription('PingZapper speeds up you online games, live streams, and network traffic')

const { t, locale } = useI18n()
const config = useRuntimeConfig()

const apiClient = useAPIClient()
const { plans } = await apiClient.plans()
const { stats } = await apiClient.stats()
const { cities } = await apiClient.cities()

const { region } = useRegion()

const interfaceImg = computed(() => {
  return `/images/interface_${locale.value}.png`
})
</script>

<template>
  <div class="home-page">
    <FlashMessage />
    <section class="hero">
      <img src="/images/logo_color.png" class="pz-icon-color" />

      <div class="tag-lines">
        <PZIconWithText />
        <span>{{ $t('home.headline_1') }}</span>
        <span>{{ $t('home.headline_2') }}</span>
        <span>{{ $t('home.headline_3') }}</span>
        <span>{{ $t('home.headline_4') }}</span>
      </div>

      <TryButton />
    </section>

    <section class="interface feature">
      <h2>{{ t('home.features_title') }}</h2>

      <div class="details">
        <div class="screenshot">
          <img :src="interfaceImg" alt="PingZapper interface" />
        </div>
        <div class="auto-add-games entry">
          <Icon name="feature-add" size="40px" />
          <h3>{{ $t('home.feature_auto_scan_1') }}</h3>
          <span>{{ $t('home.feature_auto_scan_2') }}</span>
        </div>
        <div class="multi-game entry">
          <Icon name="feature-multi-game" size="40px" />
          <h3>{{ $t('home.feature_multigame_1') }}</h3>
          <span>{{ $t('home.feature_multigame_2') }}</span>
        </div>
        <div class="performance entry">
          <Icon name="feature-performance" size="40px" />
          <h3>{{ $t('home.feature_performance_1') }}</h3>
          <span>{{ $t('home.feature_performance_2') }}</span>
        </div>
        <div class="stats entry">
          <Icon name="feature-stats" size="40px" />
          <h3>{{ $t('home.feature_metrics_1') }}</h3>
          <span>{{ $t('home.feature_metrics_2') }}</span>
        </div>
        <div class="last">
          <div class="auto-server entry">
            <Icon name="feature-servers" size="40px" />
            <h3>{{ $t('home.feature_auto_server_1') }}</h3>
            <span>{{ $t('home.feature_auto_server_2') }}</span>
          </div>
          <div class="security entry">
            <Icon name="feature-security" size="40px" />
            <h3>{{ $t('home.feature_security_1') }}</h3>
            <span>{{ $t('home.feature_security_2') }}</span>
          </div>
          <div v-if="region !== 'china'" class="region entry">
            <Icon name="feature-geography" size="40px" />
            <h3>{{ $t('home.feature_region_1') }}</h3>
            <span>{{ $t('home.feature_region_2') }}</span>
          </div>
          <div class="china-optimized entry" v-else>
            <Icon name="feature-geography" size="40px" />
            <h3>{{ $t('home.feature_china_optimized_1') }}</h3>
            <span>{{ $t('home.feature_china_optimized_2') }}</span>
          </div>
        </div>
      </div>
    </section>

    <section class="testimonials feature">
      <div class="stat-bubble">367</div>

      <h2>{{ $t('home.testimonials_title') }}</h2>

      <Testimonials />
    </section>

    <section class="games feature">
      <header>
        <span class="stat-bubble">
          {{ stats?.appCount }}
        </span>

        <h2>{{ $t('home.apps_games_headline') }}</h2>

        <NuxtLinkLocale href="/games" class="button">
          {{ $t('home.see_all_apps_games') }}
        </NuxtLinkLocale>
      </header>

      <div class="app-icons">
        <div class="app-icon">
          <img :src="`${config.public.cdnUrl}/assets/app_icons/world_of_warcraft.png`" width="64" height="64"
            alt="World of Warcraft" />
        </div>
        <div class="app-icon">
          <img :src="`${config.public.cdnUrl}/assets/app_icons/apex_legends.png`" width="64" height="64"
            alt="Apex Legends" />
        </div>
        <div class="app-icon">
          <img :src="`${config.public.cdnUrl}/assets/app_icons/league_of_legends.png`" width="64" height="64"
            alt="League of Legends" />
        </div>
        <div class="app-icon">
          <img :src="`${config.public.cdnUrl}/assets/app_icons/fortnite.png`" width="64" height="64" alt="Fortnite" />
        </div>
        <div class="app-icon">
          <img :src="`${config.public.cdnUrl}/assets/app_icons/overwatch_2.png`" width="64" height="64"
            alt="Overwatch 2" />
        </div>
        <div class="app-icon">
          <img :src="`${config.public.cdnUrl}/assets/app_icons/roblox.png`" width="64" height="64" alt="Roblox" />
        </div>
        <div class="app-icon">
          <img :src="`${config.public.cdnUrl}/assets/app_icons/destiny_2.png`" width="64" height="64" alt="Destiny 2" />
        </div>
        <div class="app-icon">
          <img :src="`${config.public.cdnUrl}/assets/app_icons/rust.png`" width="64" height="64" alt="Rust" />
        </div>
        <div class="app-icon">
          <img :src="`${config.public.cdnUrl}/assets/app_icons/black_desert_online.png`" width="64" height="64"
            alt="Black Desert Online" />
        </div>
        <div class="app-icon">
          <img :src="`${config.public.cdnUrl}/assets/app_icons/runescape.png`" width="64" height="64" alt="Runescape" />
        </div>
        <div class="app-icon">
          <img :src="`${config.public.cdnUrl}/assets/app_icons/pubg_battlegrounds.png`" width="64" height="64"
            alt="PUBG Battlegrounds" />
        </div>
        <div class="app-icon">
          <img :src="`${config.public.cdnUrl}/assets/app_icons/diablo_iv.png`" width="64" height="64" alt="Diablo IV" />
        </div>
        <div class="app-icon">
          <img :src="`${config.public.cdnUrl}/assets/app_icons/discord.png`" width="64" height="64" alt="Discord" />
        </div>
      </div>
    </section>

    <section class="servers feature">
      <header>
        <h2>
          {{
            $t(
              'home.servers_headline',
              {
                serverCount: stats?.serverCount,
                cityCount: stats?.cityCount,
                countryCount: stats?.countryCount
              }
            )
          }}
        </h2>

        <NuxtLinkLocale href="/servers" class="button">
          {{ $t('home.see_all_servers') }}
        </NuxtLinkLocale>
      </header>

      <ServerMap :cities="cities || []" />
    </section>

    <section class="plans feature">
      <h2>{{ $t('plans.title') }}</h2>

      <PlanList v-if="plans" :plans="plans" />

      <div class="actions">
        <NuxtLinkLocale href="/plans" class="button">
          {{ $t('home.see_plan_details') }}
        </NuxtLinkLocale>
        <TryButton shade="green" />
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
$app-icons-spacing: 185px;

.home-page {
  color: $color-white;
}

.hero {
  padding: 3rem;
  text-align: center;

  .pz-icon-color {
    width: 112px;
  }

  .tag-lines {
    font-size: 2.5rem;
    font-weight: 700;
    letter-spacing: -1px;

    .pz-logo.text { font-size: 2.5rem; }
    :nth-child(3) { color: $color-banana; }
    :nth-child(4) { color: $color-barbie; }
    :nth-child(5) { color: $color-ocean; }

    & > span { display: block; }
  }

  .button { margin-top: 1.5rem; }
}

.feature {
  padding: 3rem 2rem;
  border-radius: $border-radius-rounded;

  @media (min-width: $breakpoint-md) {
    padding: 4rem;
    text-align: center;

    header {
      width: 65%;
      margin: auto;
    }
  }
}

.feature + .feature {
  margin-top: 1.75rem;
}

.interface {
  position: relative;
  background-color: $color-dark-gray;
  text-align: center;

  .screenshot {
    max-width: 460px;
    margin: 0 auto;
    grid-area: screenshot;

    img {
      width: 100%;
      border-radius: 15px;
      box-shadow: 0px 0px 20px 0px rgba($color-black, 0.65);
    }
  }

  .details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
    row-gap: 2rem;
    margin-top: 1.75rem;
    text-align: left;

    grid-template-areas:
        "screenshot screenshot"
        ". ."
        ". ."
        "last last";

    .entry {
      span { color: $color-light-gray; }
      .icon { margin-bottom: 0.5rem; }
    }

    .last {
      grid-area: last;
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 2rem;
      row-gap: 2rem;
    }
  }

  @media (min-width: $breakpoint-md) {
    .details {
      display: grid;
      grid-template-columns: 1fr 40% 1fr; 
      row-gap: 2.5rem;
      column-gap: 2.5rem;

      grid-template-areas:
        ". screenshot ."
        ". screenshot ."
        "last last last";

      .last {
        display: flex;
        justify-content: center;
        
        .entry {
          min-width: 30%;
        }
      }
    }
  }
}

.testimonials {
  $testimonials-color: color.adjust(
    $color-banana, $saturation: -10%, $lightness: - 10%
  );

  color: $color-black;
  background-color: $testimonials-color;

  .stat-bubble {
    color: $testimonials-color;
    background-color: $color-black;
  }
}

.games {
  position: relative;
  overflow: hidden;
  padding-bottom: 330px;
  background-color: $color-royal;

  .stat-bubble {
    color: $color-white;
    background-color: color.adjust($color-royal, $lightness: -7.5%);
  }

  .button {
    @include button-colors($color-royal, $color-white);
  }

  .app-icons {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 150px;
    height: 300px;
    scale: 0.65;

    @media (min-width: $breakpoint-sm) {
      width: 200px;
      height: 300px;
      scale: 0.75;
    }

    @media (min-width: $breakpoint-md) {
      width: 1024px;
      height: 300px;
      scale: 1;
    }

    .app-icon {
      position: absolute;
      display: block;
      padding: 3rem;
      border-radius: $border-radius-circular;
      background-color: rgba($color-black, 0.25);
    }

    .app-icon:nth-child(1) {
      left: 10px;
      top: 0px;
      transform: rotate(-19deg);
    }

    .app-icon:nth-child(2) {
      left: -25px;
      top: $app-icons-spacing;
      transform: rotate(12deg);
    }

    .app-icon:nth-child(3) {
      left: $app-icons-spacing;
      top: 60px;
      transform: rotate(12deg);
    }

    .app-icon:nth-child(4) {
      left: $app-icons-spacing - 10px;
      top: $app-icons-spacing + 55px;
      transform: rotate(-55deg);
    }

    .app-icon:nth-child(5) {
      left: $app-icons-spacing * 2;
      top: 40px;
      transform: rotate(-45deg);
    }

    .app-icon:nth-child(6) {
      left: $app-icons-spacing * 2 + 30px;
      top: $app-icons-spacing + 30px;
      transform: rotate(0deg);
    }

    .app-icon:nth-child(7) {
      left: $app-icons-spacing * 3 - 10px;
      top: 90px;
      transform: rotate(20deg);
    }

    .app-icon:nth-child(8) {
      left: $app-icons-spacing * 4 - 25px;
      top: 30px;
      transform: rotate(-22deg);
    }

    .app-icon:nth-child(9) {
      left: $app-icons-spacing * 4 - 70px;
      top: $app-icons-spacing + 40px;
      transform: rotate(-22deg);
    }

    .app-icon:nth-child(10) {
      left: $app-icons-spacing * 5 - 40px;
      top: 0px;
      transform: rotate(10deg);
    }

    .app-icon:nth-child(11) {
      left: $app-icons-spacing * 5 - 75px;
      top: $app-icons-spacing - 10px;
      transform: rotate(-45deg);
    }
  
    .app-icon:nth-child(12) {
      left: $app-icons-spacing * 6 - 55px;
      top: -10px;
      transform: rotate(-25deg);
    }

    .app-icon:nth-child(13) {
      left: $app-icons-spacing * 6 - 75px;
      top: $app-icons-spacing + 15px;
      transform: rotate(10deg);
    }
  }
}

.servers {
  $servers-color: color.adjust(
    $color-ocean,
    $saturation: -25%,
    $lightness: -25%,
  );

  background-color: $servers-color;

  .stat-bubble {
    color: $servers-color;
  }

  .button {
    @include button-colors($servers-color, $color-white);
  }

  .server-map {
    margin-top: 2rem;
  }

  @media (min-width: $breakpoint-md) {
    .server-map {
      width: 85%;
      margin: 2rem auto 0 auto;
    }
  }
}

.plans {
  background-color: $color-white;
  color: $color-black;

  .plans {
    width: 85%;
    margin: 0 auto;
  }
  .actions {
    a + a { margin-left: 0.5rem; }
  }
}
</style>

<script setup lang="ts">
import Icon from './Icon.vue'

const { locale } = useI18n()

const data: Record<string, TestimonialProps[]> = {
  'en': [
    {
      user: 'grovir, United States',
      lines: [
        'There has been a recent lag issue with my ISP and WoW. Someone recommended the PingZapper to me and once I tried it I was so impressed that I upgraded immediately.',
        'What else can I say? Love it!'
      ]
    },
    {
      user: 'jlucktay, Australia',
      lines: [
        "Been on the trial for about 20 minutes, and I'm already sold. Having my ping under 200 is just brilliant, compared to it normally being 500-600. It feels like the game is running twice as quick. "
      ]
    },
    {
      user: 'draigars, Mexico',
      lines: [
        'The service is pretty amazing - I live in Mexico and play in French realms and I reduced my ping by more than 200ms: from ~500ms to ~200-250! Félicitations!'
      ]
    },
    {
      user: 'anderkiu, Brazil',
      lines: [
        'It really works. I play from Brasil and I have 150-220 ping using PingZapper, its just excellent.',
        'Please keep up the good work!'
      ]
    },
    {
      user: 'feefee, Malaysia',
      lines: [
        'After subscribing I went from unstable pings of 700ms to 3000ms to stable 200-300ms allowing me to /win in raids!',
        'PingZapper is simple & user friendly compared with other proxy providers.'
      ]
    },
    {
      user: 'clinician, New Zealand',
      lines: [
        'Playing an MMO on a traditional connection is completely different experience to playing an MMO through PingZapper.'
      ]
    }
  ],
  'zh-cn': [
    {
      user: 'grovir, 美国',
      lines: [
        '我的电信公司和魔兽世界最近出现了延迟问题。 有人向我推荐了PingZapper，一旦我尝试了它，我就印象深刻，以至于我立即升级了。',
        '我还能说什么？ 我喜欢！'
      ]
    },
    {
      user: 'jlucktay，澳大利亚',
      lines: [
        '试用了大约20分钟，我已经被说服了。 与通常的500-600ms延迟相比，我的延迟值低于20ms真是太棒了。 感觉游戏运行速度快了一倍。'
      ]
    },
    {
      user: 'draigars，墨西哥',
      lines: [
        '这APP非常棒 - 我住在墨西哥，在玩法国的服务器，我的延迟值减少了200多毫秒：从 ~500ms 减少到 ~200-250！ 祝贺！'
      ]
    },
    {
      user: 'anderkiu，巴西',
      lines: [
        '真的行。 我在巴西玩游戏，使用PingZapper的ping值是150-220，这非常好。',
        '请保持好的工作状态！'
      ]
    },
    {
      user: 'feefee，马来西亚',
      lines: [
        `订阅后，我的 ping 值从不稳定的 700 毫秒到 3000 毫秒，再到稳定的 200-300 毫秒，让我能够在突袭中获胜！
与其他代理提供商相比，PingZapper 简单且用户友好。`
      ]
    },
    {
      user: 'clinician，新西兰',
      lines: [
        '在传统连接上玩 MMO 与通过 PingZapper 玩 MMO 是完全不同的体验。'
      ]
    }
  ]
}

interface TestimonialProps {
  lines: string[]
  user: string
}

// const Testimonial = (props: TestimonialProps) => (
//   <div class="review">
//     <div>
//       {
//         props.lines.map((line, index) => {
//           if (index === 0) {
//             return (
//               <p key={'line_' + index}>
//                 <Icon name="quote" width="18px" />
//                 {line}
//               </p>
//             )
//           }
//           return <p key={'line_' + index}>{line}</p>
//         })
//       }
//     </div>
//     <div class="review-user">— {props.user}</div>
//   </div>
// )

const Testimonial = (props: TestimonialProps) => {
  return h('div', { class: 'review' }, [
    h('div', [
      h(Icon, { name: 'quote', width: '18px' }),
      ...props.lines.map((line, idx) => {
        if (idx === 0) return h('p', { class: 'first-line'}, line)
        return h('p', line)
      })
    ]),
    h('div', { class: 'user' }, `— ${props.user}`)
  ])
}
</script>

<template>
  <div class="reviews">
    <Testimonial
      v-for="testimonial, idx in data[locale]"
      :lines="testimonial.lines"
      :user="testimonial.user"
      :key="`testimonial-${idx}`"
    />
  </div>
</template>

<style scoped lang="scss">
.reviews {
  display: grid;
  grid-template-columns: 1fr;

  .review.extra { display: none; }

  .review:nth-last-child(-n+3) { display: none; }
}

@media (min-width: $breakpoint-md) {
  .reviews {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1.5rem;
    row-gap: 1.5rem;
    font-size: 0.9rem;

    .review:nth-last-child(-n+3) { display: block; }
  }
}

:deep(.review) {
  text-align: left;

  & > div {
    margin: 1rem 0;

    p.first-line { display: inline; }
  }

  .icon { display: inline; margin-right: 0.75rem; }
  .user { font-weight: 500; }
}
</style>

<script setup lang="ts">
import { type City } from '~/models/server'

const props = defineProps<{
  cities: City[]
}>()

interface CityPin {
  name?: string,
  code?: string,
  x: number,
  y: number,
  label: {
    x: number,
    y: number
  }
}

const cityPins: CityPin[] = [
  { name: 'SF', code: 'sfo', x: 5.5, y: 28, label: { x: -50, y: -46 } },
  { name: 'LA', code: 'lax', x: 6, y: 31, label: { x: -20, y: 20 } },
  { name: 'Dallas', code: 'dfw', x: 13, y: 32, label: { x: -40, y: -32 } },
  { name: 'Chicago', code: 'chi', x: 16.5, y: 26, label: { x: 0, y: -35 } },
  { name: 'Miami', code: 'mia', x: 17.5, y: 37, label: { x: 17, y: -10 } },
  { name: 'NYC', code: 'nyc', x: 21, y: 27, label: { x: 17, y: -6 } },
  { name: 'Mexico City', code: 'mex', x: 10.7, y: 42, label: { x: -15, y: 15 } },
  { name: 'Sao Paolo', code: 'sao', x: 28.25, y: 72.5, label: { x: 17, y: -10 } },
  { name: 'London', code: 'lon', x: 43, y: 18.25, label: { x: -75, y: -28 } },
  { name: 'Paris', code: 'par', x: 44.2, y: 22.5, label: { x: -60, y: -5 } },
  { name: 'Amsterdam', code: 'ams', x: 45, y: 17.5, label: { x: -18, y: -45 } },
  { name: 'Frankfurt', code: 'fra', x: 46.5, y: 20, label: { x: 0, y: 15 } },
  { name: 'Moscow', code: 'mow', x: 53.5, y: 15, label: { x: 16, y: 0 } },
  { name: 'Manila', code: 'mnl', x: 83.5, y: 47, label: { x: 15, y: -10 } },
  { name: 'Bangalore', code: 'blr', x: 69.25, y: 47, label: { x: -41, y: 17 } },
  { name: 'KL', code: 'kul', x: 76.25, y: 55, label: { x: 20, y: -8 } },
  { name: 'Singapore', code: 'sin', x: 77, y: 57, label: { x: 0, y: 18 } },
  { name: 'Hong Kong', code: 'hkg', x: 81, y: 40, label: { x: 16, y: -12 } },
  { name: 'Seoul', code: 'sel', x: 83.5, y: 29.5, label: { x: -28, y: -35 } },
  { name: 'Tokyo', code: 'tyo', x: 87, y: 32, label: { x: 18, y: -9 } },
  { name: 'Sydney', code: 'syd', x: 91.5, y: 82.25, label: { x: -20, y: -38 } },
  { name: 'Tel Aviv', code: 'tlv', x: 55, y: 33, label: { x: 15, y: -15 } },
  { name: 'Dubai', code: 'dxb', x: 62, y: 40, label: { x: 15, y: -15 } },
  { name: 'Bueno Aires', code: 'bue', x: 27, y: 81, label: { x: 15, y: 0 } },
]

for (const pin of cityPins) {
  const city = props.cities.find(c => c.code === pin.code)
  if (city) {
    pin.name = city.name
  }
}
</script>

<template>
  <div class="server-map">
    <img
      src="/images/world_map.svg"
      alt="Server map"
    />

    <div 
      v-for="city of cityPins"
      class="city"
      :style="{ left: `${city.x}%`, top: `${city.y}%` }"
    >
      <div class="pin"></div>
      <div class="tag" :style="{
        left: `${city.label.x}px`, top: `${city.label.y}px`
      }">
        {{ city.name }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.server-map {
  position: relative;
  user-select: none;
}

.city {
  position: absolute;

  .pin {
    position: absolute;
    width: 4px;
    height: 4px;
    border: 4px solid $color-banana;
    border-radius: $border-radius-circular;
  }

  .tag {
    display: none;
    position: relative;
    padding: 0.35rem 0.55rem;
    border-radius: $border-radius-rounded;
    background-color: rgba($color-black, 0.85);
    font-size: 0.75rem;
  }

  @media (min-width: $breakpoint-sm) {
    .pin {
      width: 5px;
      height: 5px;
      border-width: 5px;
    }

    .tag {
      display: block;
    }
  }

  @media (min-width: $breakpoint-md) {
    .tag {
      padding: 0.35rem 0.55rem;
      font-size: 0.85rem;
    }
  }
}
</style>
